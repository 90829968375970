







































































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import SeletorDeCliente from '../clientes/SeletorDeCliente.vue';
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue';
import { obrigatorio } from '@/shareds/regras-de-form';
import { FormaDePagamento, Titulo, Usuario } from '@/models';
import { dateToPtBrFormat } from '@/shareds/date/date-utils';
import { FindTituloUseCase } from '@/usecases/titulos/FindTituloUseCase';
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore';
import { FormDeFiltroDeTitulos } from './NovaTelaDeTitulos.vue';
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import CheckboxButton from '@/components/ui/CheckboxButton.vue';
import { CreateBaixaDePagamentoUseCase } from '@/usecases';
import { BaixaDePagamentoMultiploForm } from '@/models/financeiro/BaixaDePagamento';
import moment from 'moment';
import { imprimirComprovanteDeRecebivel } from "@/shareds/venda-shareds"
import CampoDecimal from '@/components/ui/CampoDecimal.vue';
import { formatarMoeda } from '@/shareds/formatadores';
import { DataOptions } from 'vuetify';

	@Component({
		components: {
			SeletorDeCliente,
			SeletorDeLojasDoUsuario,
			DataTableDeCrudPaginado,
			CheckboxButton,
			CampoDecimal,
		},
	})
export default class DialogoDePagamentoEmMassaDeTitulos extends Vue {
		@Prop({ type: Boolean, default: false }) carregando!: boolean
		mostra: boolean | null = null
		lojaId: string | null = null
		usuario: Usuario | null = null
		valor: number | null = null
		formattedValue: number | null = null
		valorAtualizado = null
		formaDePagamento = null
		formaDePagamamentoParaPagar = null
		titulosSelecionados: DialogoDePagamentoEmMassaDeTitulos['titulosFormatados'] = []
		titulos: Titulo[] = []
		totalRegistros = -1

		todosForamSelecionados = false


		findTituloUseCase = new FindTituloUseCase()
		createBaixaUseCase = new CreateBaixaDePagamentoUseCase()

		obrigatorio = obrigatorio
		formatarMoeda = formatarMoeda

		paginacao: DataOptions = {
			page: 0,
			itemsPerPage: 10,
			sortBy: [],
			sortDesc: [],
			groupBy: [],
			groupDesc: [],
			multiSort: false,
			mustSort: false,
		}

		baixaDePagamento: BaixaDePagamentoMultiploForm = {
			titulo: null,
			transactionId: '',
			valorPagamento: 0,
			valorTaxa: 0,
			dataHoraPagamento: '',
			isTaxa: false,
			isIntegracao: false,
			formaDePagamento: null,
		}
		
		headers = [
			{ text: 'Loja', value: 'loja', width: '10%' },
			{ text: 'Série', value: 'serie', width: '2%' },
			{ text: 'Número', value: 'numero', width: '2%' },
			{ text: 'Parcela', value: 'sequencia', width: '2%' },
			{ text: 'Descrição', value: 'descricao', width: '20%' },
			{ text: 'Vencimento', value: 'dataDeVencimento', width: '10%' },
			{ text: 'Data Pagamento', value: 'dataPagamento', width: '10%' },
			{ text: 'Cliente', value: 'pessoa', width: '20%' },
			{ text: 'Valor', value: 'valor', width: '12%', align: 'right' },
			{ text: 'Valor Atualizado', value: 'valorAtualizado', width: '12%', sortable: false,  align: 'right' },
			{ text: 'Juros e Multa', value: 'totalEncargos', width: '10%', sortable: false,  align: 'right' },
			{ text: 'Forma de pagamento', value: 'formaDePagamento', width: '8%' },
		]

		mostrar() {
			this.mostra = true
		}

		esconder() {
			this.mostra = false
			this.limparFiltros()
			this.titulos = []
		}

		limparFiltros() {
			this.lojaId = null;
			this.formaDePagamento = null;
			this.usuario = null;
			this.formaDePagamamentoParaPagar = null;
			this.valor = null;
			this.titulosSelecionados = [];
			this.limparSelecao
		}

		displayPagamento(formaDePagamento: string) {
			let formaFormatada
			if (formaDePagamento == 'CARTAO') formaFormatada = 'Cartão'
			if (formaDePagamento == 'DINHEIRO') formaFormatada = 'Dinheiro'
			if (formaDePagamento == 'VOUCHER') formaFormatada = 'Voucher'
			if (formaDePagamento == 'OUTROS') formaFormatada = 'Outros'
			if (formaDePagamento == 'TEF CREDITO') formaFormatada = 'TEF Crédito'
			if (formaDePagamento == 'TEF DEBITO') formaFormatada = 'TEF Débito'
			if (formaDePagamento == 'CREDIARIO') formaFormatada = 'Crediário'
			if (formaDePagamento == 'LINK') formaFormatada = 'Link'
			return formaFormatada
		}

		
		obterFiltrosDeTitulosIniciais(): FormDeFiltroDeTitulos {
			return {
				pessoa: null,
				tipoDeCliente: null,
				dataInicioPeriodo: null,
				dataFimPeriodo: null,
				situacaoDoTitulo: null,
				formaDePagamento: null,
				loja: null,
				numeroDocumento: null,
				dataInicioPagamento: null,
				dataFimPagamento: null,
				fluxo: null,
			}
		}

		desformatarTitulo(tituloFormatado) {
			return {
				id: tituloFormatado.id,
				descricao: tituloFormatado.descricaoCompleta || '', 
				valor: formatarMoeda(tituloFormatado.valor)|| 0,
				valorAtualizado: parseFloat(tituloFormatado.valorAtualizado.replace(/[^0-9,-]+/g, "").replace(",", ".")) || 0,
				juros: parseFloat(tituloFormatado.juros.replace(/[^0-9,-]+/g, "").replace(",", ".")) || 0,
				multa: parseFloat(tituloFormatado.multa.replace(/[^0-9,-]+/g, "").replace(",", ".")) || 0,
				dataDeVencimento: new Date(tituloFormatado.dataDeVencimento.split('/').reverse().join('-')),
				dataPagamento: tituloFormatado.dataPagamento ? new Date(tituloFormatado.dataPagamento.split('/').reverse().join('-')) : null,
				situacao: tituloFormatado.situacao || '',
				pessoa: tituloFormatado.pessoa || '', 
				loja: tituloFormatado.loja || '', 
				formaDePagamento: tituloFormatado.formaDePagamento || '',
				serie: tituloFormatado.serie || '', 
				numero: tituloFormatado.numero || '',
				sequencia: tituloFormatado.sequencia || '',
				temParcelamento: tituloFormatado.temParcelamento || false,
				tipoDePagamento: tituloFormatado.tipoDePagamento || '', 
				baixaPagamento: tituloFormatado.baixaPagamento || '', 
				dataDeCompetencia: tituloFormatado.dataDeCompetencia || new Date(), 
				fluxo: tituloFormatado.fluxo || '', 
			};
		}

		valorObrigatorio(v) {
			const isValid = !!v || 'Valor de pagamento é obrigatório';
			return isValid;
		}

		valorMenorQueTotal(v) {
			const total = parseFloat(this.totalSelecionado.replace(/\./g, '').replace(',', '.'));
			const valor = parseFloat(v.toString().replace(/\./g, '').replace(',', '.'));
			const isValid = (total !== null && valor <= total) || 'Valor de pagamento não pode ser maior que o valor sugerido';
			return isValid;
		}

		opcoesDeFormaDePagamentoParaBusca: FormaDePagamento[] = [
			'Dinheiro',
			'Cartão',
			'Voucher',
			'Crediário',
			'TEF Crédito',
			'TEF Débito',
			'TEF Pix',
			'Link',
			'Outros',
		]

		opcoesDeFormaDePagamento: FormaDePagamento[] = [
			'Dinheiro',
			'Cartão',
			'TEF Crédito',
			'TEF Débito',
			'TEF Pix',
			'Pix',
			'Outros',
		]

	
		get totalSelecionado() {
			return formatarMoeda(Number.parseFloat(this.titulosSelecionados.reduce((total, titulo) => {
				let valor = parseFloat(titulo.valorAtualizado.replace(/[^\d,-]/g, '').replace(',', '.'));
				if (isNaN(valor)) {
					valor = 0;
				}
				return total + valor;
			}, 0).toFixed(2)));
		}

		get totalSelecionadoSemJuros() {
			return formatarMoeda(Number.parseFloat(this.titulosSelecionados.reduce((total, titulo) => {
				let valor = parseFloat(titulo.valor.replace(/[^\d,-]/g, '').replace(',', '.'));
				if (isNaN(valor)) {
					valor = 0;
				}
				return total + valor;
			}, 0).toFixed(2)));
		}

		limparSelecao() {
			this.titulosSelecionados = []
			this.todosForamSelecionados = false
		}

		selecionarTodos() {
			this.titulosSelecionados = this.titulosFormatados
			this.todosForamSelecionados = true
		}
		
		get titulosFormatados() {
			return this.titulos.map(titulo => {

				const totalEncargos = (titulo.juros || 0) + (titulo.multa || 0);

				const maxDescriptionLength = 18;
				let descricao = titulo.descricao || '';
				const descricaoCompleta = descricao;
				if (descricao.length > maxDescriptionLength) {
					const truncated = descricao.substring(0, maxDescriptionLength);
					const lastSpaceIndex = truncated.lastIndexOf(' ');
					if (lastSpaceIndex > 0) {
						descricao = truncated.substring(0, lastSpaceIndex) + '...';
					} else {
						descricao = truncated + '...';
					}
				}


				return {
					...titulo,
					dataDeVencimento: dateToPtBrFormat(titulo.dataDeVencimento),
					descricaoCompleta,
					dataPagamento: titulo.baixaPagamento ? dateToPtBrFormat(titulo.baixaPagamento) : null,
					valor: titulo.valor?.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					}) || '',
					pessoa: titulo.pessoa?.razaoSocialOuNome || '',
					loja: titulo.loja?.nomeFantasia || '',
					formaDePagamento: titulo.formaDePagamento
						? this.displayPagamento(titulo.formaDePagamento)
						: '',
					valorAtualizado: titulo.valorAtualizado?.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					}) || '',
					juros: titulo.juros?.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					}) || '',
					multa: titulo.multa?.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					}) || '',
					totalEncargos: totalEncargos.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					}) || '',
					descricao: descricao,
				};
		
			});
		}

		get tokenDoUsuario() {
			return UserLoginStore.token
		}
		@Watch('lojaId')
		onLojaIdChange() {
			this.buscar();
		}

		@Watch('usuario')
		onUsuarioChange() {
			this.buscar();
		}

		@Watch('formaDePagamento')
		onFormaDePagamentoChange() {
			this.buscar();
		}
	
		@Watch('paginacao')
		async buscar() {
			try {
				this.titulosSelecionados = []
				if(!this.usuario) return
				if(!this.usuario.id) return
				if(!this.lojaId) return
				const page = await this.findTituloUseCase.findParaBaixa({
					lojaId: this.lojaId,
					clienteId: this.usuario?.id,
					formaDePagamento: this.formaDePagamento,
					page: this.paginacao.page - 1,
					size: this.paginacao.itemsPerPage,
				})
				this.titulos = page.content
				this.totalRegistros = page.totalElements
			} catch (e) {
				AlertModule.setError(e)
			}
		}

		async salvar() {
			if (!this.baixaDePagamento) return
			if (!this.titulosSelecionados) return
			if(!this.valor) return
			const totalSelecionadoDesformatado = Number(parseFloat(this.totalSelecionado.replace(/[^0-9,-]+/g, "").replace(",", ".")) || 0);
			
			if(this.valor > totalSelecionadoDesformatado) {
				return AlertModule.setError('Valor de pagamento não pode ser maior que o valor sugerido')
			}
			try {
				const titulosSelecionadosOriginais = this.titulosSelecionados.map(selecionado => {
					const titulo = this.titulos.find(t => t.id === selecionado.id);
					if (!titulo) {
						throw new Error(`Titulo com ID ${selecionado} não encontrado.`);
					}
					return titulo;
				});


				this.baixaDePagamento.titulo = titulosSelecionadosOriginais
				this.baixaDePagamento.valorPagamento = !this.valor ? 0 : this.valor
				this.baixaDePagamento.formaDePagamento = this.formaDePagamamentoParaPagar
				this.baixaDePagamento.transactionId = ''

				
				const now = new Date();
				const formattedDate = now.toISOString().split('T')[0]; // yyyy-mm-dd
				const formattedTime = now.toTimeString().split(' ')[0]; // hh:mm:ss

				const formattedDateTime = this.gerarDataHora(formattedDate ,formattedTime);

				this.baixaDePagamento.dataHoraPagamento = formattedDateTime.toString();
				const comprovanteDasBaixas = await this.createBaixaUseCase.createMultiplo(this.baixaDePagamento)
				
				await imprimirComprovanteDeRecebivel(comprovanteDasBaixas)
				this.$emit('atualizar')
				this.esconder()
		
			} catch (error) {
				AlertModule.setError(error)
			} finally {
				this.mostra = false
			}
		}

		gerarDataHora(data?: string | null, hora?: string | null): string {
			const dateTimeString = `${data}T${hora}:00`;
			const localDateTime = moment(dateTimeString, 'YYYY-MM-DDTHH:mm:ss');
			const utcDateTime = localDateTime.utcOffset(0, true); // Define o offset para 0 (UTC)
			const utcDateTimeString = utcDateTime.toISOString();

			return utcDateTimeString;
		}

}
