

































































































































































































































































































































































































import { Vue, Component, Ref } from 'vue-property-decorator'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import SeletorDeTipoDeCliente from '@/components/tabeladepreco/SeletorDeTipoDeCliente.vue'
import CamposDeFiltrosDeTitulos from '@/views/application/titulos/CamposDeFiltrosDeTitulos.vue'
import DialogoDePagamentoEmMassaDeTitulos from '@/views/application/titulos/DialogoDePagamentoEmMassaDeTitulos.vue'
import SeletorDeCliente from '../clientes/SeletorDeCliente.vue'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import DatePicker from '@/components/ui/DatePicker.vue'
import { Pageable } from '@/models/Pageable'
import { CancelToken } from 'axios'
import {
	FiltroDeTitulos,
	FluxoDoTitulo,
	Page,
	Pessoa,
	SituacaoDoTitulo,
	TipoDeCliente,
	TipoDePessoa,
	Titulo,
	TotalizadoresDeTitulo,
} from '@/models'
import { DeleteTituloUseCase, FindTituloUseCase } from '@/usecases'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { dateToPtBrFormat } from '@/shareds/date/date-utils'
import DialogoDeEdicaoDeTitulo from './DialogoDeEdicaoDeTitulo.vue'
import CheckboxButton from '@/components/ui/CheckboxButton.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import Confirmacao from '@/components/ui/Confirmacao.vue'

type AbaDeTitulo = 'Dash 1' | 'Dash 2'

const FILTRO_DE_TITULOS = 'FILTRO_DE_TITULOS'

@Component({
	components: {
		SeletorDeLojasDoUsuario,
		SeletorDeTipoDeCliente,
		CamposDeFiltrosDeTitulos,
		SeletorDeCliente,
		DataTableDeCrudPaginado,
		DatePicker,
		DialogoDeEdicaoDeTitulo,
		CheckboxButton,
		DialogoDePagamentoEmMassaDeTitulos,
		Confirmacao,
	},
})
export default class NovaTelaDeTitulos extends Vue {
	@Ref() dialogoDeFiltros!: CamposDeFiltrosDeTitulos
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() dialogoDeEdicao!: DialogoDeEdicaoDeTitulo
	@Ref() form!: HTMLFormElement
	@Ref() dialogoDePagamentoEmMassaDeTitulos!: DialogoDePagamentoEmMassaDeTitulos
	@Ref() confirmacaoRemocao!: Confirmacao

	deleteTituloUseCase = new DeleteTituloUseCase()
	findTituloUseCase = new FindTituloUseCase()

	tabs: AbaDeTitulo[] = []
	titulos: Titulo[] = []
	totalizador: TotalizadoresDeTitulo | null = null
	titulosSelecionados: NovaTelaDeTitulos['titulosFormatados'] = []
	tituloParaDeletar: Titulo | null = null

	buscando = false
	show = false
	show2 = false
	lojaId = ''
	tipoDeCliente = ''
	tab: null | number = null
	todosForamSelecionados = false
	menuAberto = false
	indice: null | number = null

	paginacao = {
		page: 0,
		itemsPerPage: 10,
		itemsLength: 10,
	}

	filtros: FormDeFiltroDeTitulos = this.obterFiltrosDeTitulosIniciais()

		headers = [
			{ text: 'Loja', value: 'loja' },
			{ text: 'Série', value: 'serie'},
			{ text: 'Número', value: 'numero'},
			{ text: 'Parcela', value: 'sequencia' },
			{ text: 'Descrição', value: 'descricao' },
			{ text: 'Vencimento', value: 'dataDeVencimento' },
			{ text: 'Data Pagamento', value: 'dataPagamento', sortable: false},
			{ text: 'Cliente', value: 'pessoa'},
			{ text: 'Valor', value: 'valor', width: '15%', align: 'right' },
			{ text: 'Valor Atualizado', value: 'valorAtualizado', width: '15%', sortable: false,  align: 'right' },
			{ text: 'Juros e Multa', value: 'totalEncargos', width: '15%', sortable: false,  align: 'right' },
			{ text: 'Forma de pagamento', value: 'formaDePagamento' },
		]


		aplicarFiltros() {
			localStorage[FILTRO_DE_TITULOS] = JSON.stringify(this.filtros)
			this.dataTablePaginado.reiniciar(this.filtrosFormatados)
			this.buscar
		}

		exibeConfirmacao(indice: number) {
			this.tituloParaDeletar = this.itens[indice]
			this.confirmacaoRemocao.mostrar()
		}

		abrirFiltros() {
			this.dialogoDeFiltros.mostrar()
		}

		limparSelecao() {
			this.titulosSelecionados = []
			this.todosForamSelecionados = false
		}

		selecionarTodos() {
			this.titulosSelecionados = this.titulosFormatados
			this.todosForamSelecionados = true
		}

		abrirDialogoDeEdicaoDeTitulo(indice: number) {
			this.dialogoDeEdicao.mostrarDialogo(this.titulos[indice], 'editar')
		}

		abrirDialogoDeCriacaoDeTitulo() {
			this.dialogoDeEdicao.mostrarDialogo('Entrada', 'criar')
		}

		mostrarPagamentoEmMassa() {
			this.dialogoDePagamentoEmMassaDeTitulos.mostrar()
		}

		insereTitulo(titulos: Array<Titulo>) {
			this.titulos = this.titulos.concat(titulos)
			this.dataTablePaginado.reiniciar(this.filtrosFormatados)
		}

		formatarNumero(numero) {
			const numeroFormatado = numero.toLocaleString('pt-BR', {
				style: 'currency',
				currency: 'BRL',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			});
			return numeroFormatado;
		}

		get abasPermitidas() {
			this.tabs = ['Dash 1', 'Dash 2']
			return this.tabs
		}

		get tokenDoUsuario() {
			return UserLoginStore.token
		}

		get tipoDePessoa(): TipoDePessoa {
			return 'Cliente'
		}

		get titulosFormatados() {
			return this.titulos.map(titulo => {
				const totalEncargos = (titulo.juros || 0) + (titulo.multa || 0);

				const maxDescriptionLength = 18; 
				let descricao = titulo.descricao || '';
				const descricaoCompleta = descricao;
				if (descricao.length > maxDescriptionLength) {
					const truncated = descricao.substring(0, maxDescriptionLength);
					const lastSpaceIndex = truncated.lastIndexOf(' ');
					if (lastSpaceIndex > 0) {
						descricao = truncated.substring(0, lastSpaceIndex) + '...';
					} else {
						descricao = truncated + '...';
					}
				}


				return {
					...titulo,
					dataDeVencimento: dateToPtBrFormat(titulo.dataDeVencimento),
					descricaoCompleta,
					dataPagamento: titulo.baixaPagamento ? dateToPtBrFormat(titulo.baixaPagamento) : null,
					valor: titulo.valor?.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					}) || '',
					pessoa: titulo.pessoa?.razaoSocialOuNome || '',
					loja: titulo.loja?.nomeFantasia || '',
					formaDePagamento: titulo.formaDePagamento
						? this.displayPagamento(titulo.formaDePagamento)
						: '',
					valorAtualizado: titulo.valorAtualizado?.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					}) || '',
					juros: titulo.juros?.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					}) || '',
					multa: titulo.multa?.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					}) || '',
					totalEncargos: totalEncargos.toLocaleString('pt-br', {
						style: 'currency',
						currency: 'BRL',
					}) || '',
					descricao: descricao, 
				};
		
			});
		}



		get filtrosFormatados(): FiltroDeTitulos {
			return {
				tipoDeCliente: this.filtros.tipoDeCliente?.id || undefined,
				pessoa: this.filtros.pessoa?.id || undefined,
				dataInicioPeriodo: this.filtros.dataInicioPeriodo || undefined,
				dataFimPeriodo: this.filtros.dataFimPeriodo || undefined,
				situacaoDoTitulo: this.filtros.situacaoDoTitulo || undefined,
				formaDePagamento: this.filtros.formaDePagamento || undefined,
				loja: this.filtros.loja || undefined,
				numeroDocumento: this.filtros.numeroDocumento || undefined,
				dataInicioPagamento: this.filtros.dataInicioPagamento || undefined,
				dataFimPagamento: this.filtros.dataFimPagamento || undefined,
			}
		}

		obterFiltrosDeTitulosIniciais(): FormDeFiltroDeTitulos {
			return {
				pessoa: null,
				tipoDeCliente: null,
				dataInicioPeriodo: null,
				dataFimPeriodo: null,
				situacaoDoTitulo: null,
				formaDePagamento: null,
				loja: null,
				numeroDocumento: null,
				dataInicioPagamento: null,
				dataFimPagamento: null,
				fluxo: "Entrada",
			}
		}

		displayPagamento(formaDePagamento: string) {
			let formaFormatada
			if (formaDePagamento == 'CARTAO') formaFormatada = 'Cartão'
			if (formaDePagamento == 'DINHEIRO') formaFormatada = 'Dinheiro'
			if (formaDePagamento == 'VOUCHER') formaFormatada = 'Voucher'
			if (formaDePagamento == 'OUTROS') formaFormatada = 'Outros'
			if (formaDePagamento == 'TEF CREDITO') formaFormatada = 'TEF Crédito'
			if (formaDePagamento == 'TEF DEBITO') formaFormatada = 'TEF Débito'
			if (formaDePagamento == 'CREDIARIO') formaFormatada = 'Crediário'
			if (formaDePagamento == 'LINK') formaFormatada = 'Link'
			return formaFormatada
		}

		async buscar(
			paginavel: Pageable,
			parametros: {
			nomeDoCliente?: string
			tipoDeCliente?: string
			loja?: string
			numeroDocumento?: string
		},
			cancelToken: CancelToken,
		): Promise<Page<Titulo>> {
			const pagina = await this.findTituloUseCase.find(
				{
					...paginavel,
					...parametros,
					fluxo: 'Entrada',
					token: this.tokenDoUsuario,
				},
				{
					cancelToken,
				},
			)
			this.titulos = pagina.content
			this.buscarTotalizador()
			return pagina
		}

		async buscarTotalizador() {
			try {
				const pagina = await this.findTituloUseCase.findTotalizador({
					...this.filtrosFormatados,
					fluxo: 'Entrada',
					token: this.tokenDoUsuario,
				});

				this.totalizador = pagina;
				
				return this.totalizador;
			}catch (e) {
				AlertModule.setError(e)
			}
		}

		async exportarCsv() {
			try {
				this.filtrosFormatados.fluxo = "Entrada"
				AlertModule.setSuccess('O arquivo está sendo gerado. O processo pode levar alguns minutos.')
				const response: any = await this.findTituloUseCase.exportCsv(this.filtrosFormatados)
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', `titulos.csv`)
				document.body.appendChild(link)
				link.click()
			} catch (e) {
				AlertModule.setError(e)
			} 
		}

		get itens() {
			return this.titulos
		}

		async removerTitulo() {
			if (!this.tituloParaDeletar) return;

			try {
				const tituloId = this.tituloParaDeletar.id;
				if (tituloId) {
					await this.deleteTituloUseCase.execute(tituloId);
					this.titulos = this.titulos.filter(({ id }) => id !== tituloId);
				}
			} catch (error) {
				AlertModule.setError(error);
			}
		}

}

export interface FormDeFiltroDeTitulos {
	pessoa: null | Pessoa
	tipoDeCliente: TipoDeCliente | null
	dataInicioPeriodo: string | null
	dataFimPeriodo: string | null
	situacaoDoTitulo: SituacaoDoTitulo | null
	formaDePagamento: string | null
	loja: string | null
	numeroDocumento: string | null
	dataInicioPagamento: string | null
	dataFimPagamento: string | null
	fluxo: FluxoDoTitulo | null
}
