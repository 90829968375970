

















































import { AnexoDosProcessos } from '@/models/anexo/AnexoDosProcessos'
import { downloadFilesFromS3 } from '@/shareds/s3/files'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class BotaoDeDownloadDeAnexo extends Vue {
	@Prop() anexo!: AnexoDosProcessos

	baixandoAnexos = false
	baixandoArquivo: string[] = []

	async baixarArquivo(caminhoDaPasta: string, nomeDoArquivo: string) {
		this.baixandoArquivo.push(nomeDoArquivo)
		try {
			const pattern = /[\s~`!@#$%^&*()áÁàÀ_\-+={}[\]|\\;:'",<>/?]+/g;
			if(pattern.test(nomeDoArquivo)){
				AlertModule.setInfo("Parece que o arquivo salvo contia espaços e/ou caracteres, o arquivo anexado poderá estar corrompido, nesses casos será necessario anexar o arquivo novamente!");
			}
			await downloadFilesFromS3(caminhoDaPasta, nomeDoArquivo)
			this.baixandoArquivo = this.baixandoArquivo.filter(nome => nomeDoArquivo !== nome)
		} catch (error) {
			AlertModule.setError('Houve um problema ao baixar o arquivo')
		} finally {
			this.baixandoArquivo = this.baixandoArquivo.filter(
				nome => nomeDoArquivo !== nome,
			)
		}
	}

	extrairNomeDosDocumentos(url: string) {
		const lastSlashIndex = url.lastIndexOf("/")
		const fileName = url.substring(lastSlashIndex + 1)
		return fileName
	}

	extrairCaminho(url: string) {
		const filePath = url.replace(/.*\/([^/]+\/[^/]+\/[^/]+)\/[^/]+$/, "$1");

		return filePath
	}
}

